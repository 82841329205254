body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  height: 100%;
}

body {
  min-width: 1300px;
}

#root {
  position: relative;
}

.app-load {
  height: 100%;
  width: 100%;
  background: linear-gradient(152deg, #0B2F96 0%, #1961AF 100%);;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: 2px;
}

.banner {
  position: relative;
  width: 100%;
  height: 500px;
  background: linear-gradient(152deg, #0B2F96 0%, #1961AF 100%);;
  border-bottom-right-radius: 80px;
  overflow: hidden;

  .left {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .banner-title-img {
      width: 240px;
    }

    .banner-tip {
      margin-top: 15px;
      margin-bottom: 19px;
      font-size: 18px;
      color: rgba($color: #FFFFFF, $alpha: 0.7);
      font-weight: 300;
    }

  }
}
.delay100 {
  animation-delay: 100ms !important;
}
.delay200 {
  animation-delay: 200ms !important;
}
.delay300 {
  animation-delay: 300ms !important;
}
.delay400 {
  animation-delay: 400ms !important;
}
.delay500 {
  animation-delay: 500ms !important;
}
.delay600 {
  animation-delay: 600ms !important;
}
.delay700 {
  animation-delay: 700ms !important;
}
.delay800 {
  animation-delay: 700ms !important;
}
.delay900 {
  animation-delay: 700ms !important;
}
.delay1 {
  animation-delay: 1s !important;
}

.fadeInLeft {
  will-change: transform;
  animation: niLeft 0.5s both;
}
.fadeInRight {
  will-change: transform;
  animation: niRight 0.5s both;
}
.fadeInBottom {
  will-change: transform;
  animation: niBottom 0.5s both;
}

@keyframes niLeft {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes niRight {
  0% {
    opacity: 0;
    transform: translate3d(150%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes niBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 150%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

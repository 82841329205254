.header {
    width: 100%;
    height: 70px;
    background: rgba($color: #000000, $alpha: 0.9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px;

    .logo {
        width: 130px;
        cursor: pointer;
    }

    ul {
        margin: 0px;
    }

    .menu {
        list-style: none;
        display: flex;
        align-items: center;

        font-size: 14px;
        color: #FFFFFF;

        .menu-item {
            width: 70px;
            height: 70px;
            line-height: 70px;

            margin-right: 30px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;



            &-active,
            &:hover {
                border-bottom: 4px solid #5774FF;
            }
        }

        .sub-menu-item {
            position: relative;
            width: 80px;
            height: 70px;
            line-height: 70px;

            margin-right: 40px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;



            &-active,
            &:hover {
                border-bottom: 4px solid #5774FF;
            }


            .sub-menu {
                list-style: none;
                position: absolute;
                z-index: 10;
                width: 140px;
                padding: 0px;
                height: 0px;
                box-sizing: border-box;
                transition: all ease-out 0.2s;
                overflow: hidden;

                background-color: #000000;

                .sub-menu-name {
                    &:first-child {
                        margin-top: 11px;
                    }

                    &:last-child {
                        margin-bottom: 11px;
                    }

                    line-height: 40px;
                    height: 40px;
                    text-align: left;
                    padding-left: 24px;

                    overflow: hidden;
                    transition: all ease-out 0.2s;
                    position: relative;

                    span {
                        position: relative;
                        z-index: 10;
                    }

                    &-active {
                        background-color: #5774FF;
                    }



                    &:after {
                        background: transparent;
                        left: -55%;
                        top: -50%;
                        position: absolute;
                        z-index: 3;
                        width: 0%;
                        height: 300%;
                        content: "";
                        transition: all ease-out 0.3s;
                        transform: rotate(-20deg);
                        background-color: #5774FF;
                    }


                    &:hover {
                        &:after {
                            width: 200%;
                        }

                        span {
                            color: #FFFFFF;
                        }
                    }
                }
            }

            &:hover {
                .sub-menu {
                    height: 215px;
                    transition: all ease-out 0.2s;
                }
            }
        }

        .menu-line {
            width: 1px;
            height: 14px;
            border: 1px solid #979797;
            margin-right: 30px;
        }

        .last {
            width: 50px;
            margin-right: 0px;

            .iconUser {
                font-size: 18px;
            }
        }
    }
}
.footer {
    width: 100%;
    height: 160px;
    background: rgba($color: #000000, $alpha: 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    font-size: 12px;
    font-weight: 300;
    color: rgba($color: #ffffff, $alpha: 0.5);
    line-height: 24px;

    .link {
        color: rgba($color: #ffffff, $alpha: 0.5);

        &:hover {
            color: #5774FFFF;
        }

    }
    .police_logo{
        height: 14px;
        position: relative;
        top: -2px;
    }
}
.success-modal {
    .ant-modal-header {
        border-bottom: none;

        .ant-modal-title {
            position: relative;

            .icon-form {
                height: 125px;
                position: absolute;
                top: -80px;
                left: 175px;
            }
        }

    }

    .ant-modal-body {
        padding: 30px 40px;
        padding-bottom: 30px;
    }

    .content {
        .show-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 400px;

            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
            text-align: center;
        }

        .tip {
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #999999;
        }
    }
}
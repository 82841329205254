.open-modal-button{
    display: block;
    max-width: 140px;
}
.hrtps-form-modal {
    .ant-modal-header {
        border-bottom: none;

        .ant-modal-title {
            position: relative;

            .icon-form {
                height: 125px;
                position: absolute;
                top: -80px;
                left: 175px;
            }
        }

    }

    .ant-modal-body {
        padding: 30px 40px;
        padding-bottom: 30px;
    }

    .content {
        .title {
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
        }

        .ant-form-item {
            display: block;
            margin-bottom: 16px;

            .ant-form-item-label {
                margin-bottom: 2px;
                text-align: left;

                .ant-form-item-required {
                    height: 22px;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    color: #333333;

                    &::before {
                        content: '';
                        margin: 0px;
                    }

                    &::after {
                        display: inline-block;
                        margin-left: 4px;
                        color: #ff4d4f;
                        font-size: 14px;
                        line-height: 1;
                        content: '*';
                    }
                }
            }

            .ant-form-item-control {
                .ant-form-item-explain {
                    position: absolute;
                    top: 6px;
                    right: 0px;
                    font-size: 12px;
                    color: #FF0000;
                }
            }

            .ant-form-item-control-input-content {
                .ant-input {
                    border: none;
                    border-bottom: 1px #979797FF solid;
                    padding: 6px 0px;
                    outline: none;


                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;

                    &:focus,
                    &-focused {
                        box-shadow: none
                    }
                }
            }


        }

        .button {
            width: 140px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #5774FF;
            border-radius: 20px;
            margin-top: 34px;
            margin-left: auto;
            margin-right: auto;


            font-size: 14px;
            color: #FFFFFF;

            cursor: pointer;

            &:hover {
                background-color: #5774FF;
            }

            .spin {
                .ant-spin-dot-item {
                    background-color: #FFFFFF;
                }
            }
        }
    }
}